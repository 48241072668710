.container
  display: flex
  align-items: center
  justify-content: center
  flex-direction: column
.imagesContainer
  background-color: #F2F2F7
  width: 80px
  height: 80px
  border-radius: 40px
  justify-content: center
  align-items: center
  display: flex

.descriptionAndTitleContainer
  width: 100%
  align-items: center
  justify-content: center
  display: flex
  flex-direction: column
  margin: 8px 0 8px 0

.title
  color: #503E9D
  font-weight: 400
  font-size: 14px
  text-align: center

.progressContainer
  margin: 0 16px 0 16px

.rotateImage
  animation: rotate-image infinite 3s linear

@keyframes rotate-image
  from
    transform: rotate(0deg)
  to
    transform: rotate(360deg)
