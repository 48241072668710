.wrapper
    display: flex
    flex-direction: column
    border: 1px solid #E5E5EA
    border-radius: 8px
    margin-top: 24px
    // overflow: hidden
    .triggerSettingsContainer
        display: flex
        flex-direction: row
        align-items: center
        padding: 11px 80px 11px 24px
        background: #F9F9FB
        min-height: 80px
    .triggerSettingsTitle
        font-weight: 700
        font-size: 14px
        line-height: 19px
        text-transform: uppercase
        color: #65656C
        padding: 8px 24px
        background: #F2F2F7
        width: 100%

    .headWrapper
        display: flex
        flex-direction: column
        padding: 16px 24px
        width: 100%
        background: #FFFFFF
        box-shadow: 0px 4px 8px rgba(142, 142, 147, 0.12)
        .error
            display: flex
            justify-content: flex-end
            padding-right: 122px
            width: 100%
            font-weight: 400
            font-size: 14px
            color: #EE5253
        .headerContent
            display: flex
            flex-direction: row
            justify-content: space-between
            align-items: center
            width: 100%
            .startingDateWrapper
                display: flex
                flex-direction: row
                align-items: center
                .repeatSwitchLabel
                    font-weight: 600
                    font-size: 16px
                    color: #1C1C1C
                    margin-right: 11px
                .repeatSwitch ,.activeRepeatSwitch
                    border: 1px solid #D1D1D6
                    border-radius: 8px
                    padding: 8px 12px
                    display: flex
                    flex-direction: row
                    align-items: center
                .activeRepeatSwitch
                    background: #FFF4EA !important
                .removeIconWrapper
                    cursor: pointer
                    width: 40px
                    height: 40px
                    background: #F9F9FB
                    border-radius: 88px
                    display: flex
                    align-items: center
                    justify-content: center
                    margin-left: 16px
            .headTitle
                font-weight: 700
                font-size: 20px
                color: #1C1C1C
