.csvWrapprt
  display: flex
  justify-content: center
  flex-direction: column
  align-items: center

.csvContainer
  background-color: #F2F2F7
  width: 80px
  height: 80px
  border-radius: 40px
  justify-content: center
  align-items: center
  display: flex

.csvButtonContainer
  display: flex
  flex-direction: row
  align-items: center
  justify-content: center
  // width: 50%
  align-self: center

.csvButtonWrapper
  background-color: #FFA351
  width: 25%
  margin: 8px
  position: relative
  justify-content: center
  align-items: center
  display: flex
  border-radius: 8px
  cursor: pointer

.csvUploadContainer
  height: 48px
  width: 100%
  display: flex
  flex-direction: row

.uploadImageContainer
  display: flex
  align-items: center
  justify-content: flex-end
  width: 35%

.customFileInput::-webkit-file-upload-button
  visibility: hidden

.customFileInput::before
  content: Upload CSV file
  display: inline-block

.uploadTitle
  color: #FFFFFF
  font-weight: 700

.downloadButtonContainer
  width: 25%
  height: 48px
  border-radius: 8px
  display: flex
  align-items: center
  justify-content: center
  background: #F9F9FB

.files_count
  color: #503E9D
  text-align: center
  font-weight: bold

.dowloadButtonContainer
  margin: 8px 0 0 8px

input[type=file]
  opacity: 0

.dowloadTempTitle
  color: #1C1C1C !important
  font-weight: 700
  a
    color: #1C1C1C !important

.uploadTitleWrapper
  position: absolute
  top: 14px
  pointer-events: none

.wrapper
  margin-top: 24px
  padding: 56px
  border-radius: 10px
  border: solid 1px #E5E5EA

.fileInfo
  display: flex
  align-content: center
  justify-content: space-between
  border: 1px solid #F2F2F7
  border-radius: 8px
  height: 60px
  padding: 0
  width: 50%
  overflow: hidden
  margin-bottom: 20px
  .imageWrapper
    background: #F2F2F7
    height: 60px
    width: 60px
    line-height: 60px
    text-align: center
  .fileContent
    flex: 8
    p
      padding: 0px 5px
      text-align: left
      line-height: 60px
      font-weight: normal
      font-size: 12px
      margin: 0
      height: 60px
