.wrapper
    display: flex
    flex-direction: row
    align-items: center
    width: 100%
    .separator
        margin: 0 8px
    .messageOnWrapper
        display: flex
        flex-direction: row
        align-items: center
        width: 100%
        .messageOnTitle
            font-weight: 600
            font-size: 16px
            color: #1C1C1C
            margin-right: 8px
.triggrer_styles
    height: 40px
    min-width: 200px
    max-width: 100%
    border-radius: 8px
    display: flex
    justify-content: space-between
    align-items: center
    border: solid 1px #c7c7c7
    padding: 0px 10px
