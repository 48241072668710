@import "antd/dist/antd.less";

.ant-select-single.ant-select-lg:not(.ant-select-customize-input)
  .ant-select-selector {
  border-color: #d1d1d6;
}

.ant-select-arrow {
  font-size: 16px;
  color: #000;
}

.ant-picker-input-modifier {
  width: 100% !important ;
  .ant-picker-suffix {
    width: 5%;
  }
  .ant-picker-clear {
    width: 5%;
  }
}

@primary-color: #FFA351;@link-color: #FFA351;@success-color: #52c41a;@warning-color: #faad14;@error-color: #f5222d;@font-size-base: 14px;@heading-color: rgba(0, 0, 0, 0.85);@text-color: #1C1C1E;@text-color-secondary: rgba(0, 0, 0, 0.45);@disabled-color: rgba(0, 0, 0, 0.25);@border-radius-base: 4px;@border-color-base: #e5e5ea;