
.promocodeSettingsCotnainer
  display: flex
  justify-content: space-between
  align-items: center
  flex-direction: row
  margin: 10px 25px

.promocodeDropdownWrapper
  width: 100%

.promocodeTypes
  width: 100%
  margin: 0 24px 0 24px

.switchToggleContainer, .activeSwitch
  display: flex
  align-items: center
  justify-content: space-between
  background-color: #FFF4EA
  border-radius: 8px
  height: 82px
  margin-top: 16px
  margin-bottom: 20px
  padding: 0 24px 0 24px
  border: 1px solid#E5E5EA
  width: 100%

.activeSwitch
  background-color: #F9F9FB

.maskedPromoTitle
  color: #1C1C1C,
  font-size: 16px

.label
  font-size: 16px
  font-weight: 600

.divider
  display: flex
  flex-direction: row
  justify-content: space-between
  align-items: flex-start
  width: 100%
  *
    width: 100%

.pointMultiplierContainer
  width: 100%
  margin: 32px 24px 0 0

.label
  font-size: 16px
  font-weight: bold
  margin-bottom: 10px

.line
  background: rgb(229 229 234)
  width: 100%
  height: 1px
  border: none
  margin: 40px 0 10px 0
  display: flex
  justify-content: center
  align-items: center

.genericSwitch, .labeldiscount_percentage
  font-size: 16px
  font-weight: bold

.formControl
  display: flex
  flex-direction: column
  justify-content: flex-start
  align-items: flex-start
  paddding: 10px
  height: auto
  margin: 10px 0px 10px
