.any-select .ant-select-selector {
  background-color: #ffa351 !important;
  height: 48px !important;
  justify-content: center;
  align-items: center;
  display: flex;
  border-radius: 8px;
  color: #fff;
}

.any-select .ant-select-dropdown {
  min-width: auto !important;
  width: auto !important;
}

.select-country.ant-select-lg:not(.ant-select-customize-input)
  .ant-select-selector {
  border-radius: 8px;
}
.select-country .ant-select-selector {
  height: 50px !important;
}

.countrySelector .ant-select-selector .ant-select-selection-placeholder {
  line-height: 38px;
  align-items: center;
  display: flex;
}

.any-select .ant-select-selector .ant-select-selection-placeholder {
  color: #fff;
  font-weight: 700;
}

.ant-select {
  display: initial;
  width: 100%;
}

.addBrandArrow .ant-select-selector .ant-select-selection-item span {
  color: #fff !important;
  font-weight: 600;
}

.addBrandArrow .ant-select-arrow {
  color: #fff;
}
.selectedPlaceholder .ant-select-selector .ant-select-selection-placeholder {
  color: #1c1c1c;
  height: 52;
}

.arrowStyle .ant-select-arrow {
  color: #000;
  right: 20px;
}

.any-select {
  display: flex;
  justify-content: center;
  align-items: center;
}

.ant-select-selection-item {
  display: flex;
  align-items: center;
  color: #1c1c1c !important;
}

.any-select:not(.ant-select-customize-input) .ant-select-selector {
  position: relative;
  background-color: #fff;
  border: 1px solid #ffa351;
  border-radius: 8px;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.arrow .ant-select-arrow {
  left: 46%;
  height: 35px;
}
.input-feild .ant-select-lg:not(.ant-select-customize-input)
  .ant-select-selector {
  border-radius: 8px;
  border: 1px solid #eee;
  height: 62px !important;
}
.genericContainer .ant-space-align-center {
  margin-top: 5px;
}
@primary-color: #FFA351;@link-color: #FFA351;@success-color: #52c41a;@warning-color: #faad14;@error-color: #f5222d;@font-size-base: 14px;@heading-color: rgba(0, 0, 0, 0.85);@text-color: #1C1C1E;@text-color-secondary: rgba(0, 0, 0, 0.45);@disabled-color: rgba(0, 0, 0, 0.25);@border-radius-base: 4px;@border-color-base: #e5e5ea;