.csvWrapprt
  display: flex
  justify-content: center
  flex-direction: column
  align-items: center

.csvContainer
  background-color: #F2F2F7
  width: 80px
  height: 80px
  border-radius: 40px
  justify-content: center
  align-items: center
  display: flex

.csvButtonContainer
  display: flex
  align-items: center
  flex-direction: row

.csvButtonWrapper
  background-color: #FFA351
  width: 50%
  margin: 8px
  position: relative
  justify-content: center
  align-items: center
  display: flex
  border-radius: 8px
  cursor: pointer

.csvUploadContainer
  height: 48px
  width: 100%
  display: flex
  flex-direction: row

.uploadImageContainer
  display: flex
  align-items: center
  justify-content: flex-end
  width: 35%

.customFileInput::-webkit-file-upload-button
  visibility: hidden

.customFileInput::before
  content: Upload CSV file
  display: inline-block

.uploadTitle
  color: #FFFFFF
  font-weight: 700

.downloadButtonContainer
  width: 50%
  height: 48px
  border-radius: 8px
  display: flex
  align-items: center
  justify-content: center

.dowloadButtonContainer
  margin: 8px 0 0 8px

input[type=file]
  opacity: 0

.dowloadTempTitle
  color: #1C1C1C
  font-weight: 700

.uploadTitleWrapper
  position: absolute
  top: 14px
  pointer-events: none
