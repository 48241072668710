.modalHeaderContainer
  display: flex
  justify-content: flex-end
  height: 64px

.closeIconContainer
  background-color: #F2F2F7
  border-radius: 16px
  width: 32px
  height: 32px
  justify-content: center
  align-items: center
  display: flex
  cursor: pointer

.closeIcon
  width: 24px
  height: 24px
