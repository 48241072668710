.wrapper
    display: flex
    flex-direction: column
    padding: 24px
    background: #FFFFFF
    border-radius: 8px
    width: 100%
    margin: 24px 0
    .segmentTitleWrapper
        display: flex
        flex-direction: row
        align-items: center
        justify-content: space-between
        width: 100%
        .selectedUserWrapper
            display: flex
            flex-direction: row
            align-items: center
            .selectedUserIcon
                width: 16px
                height: 16px
                margin-right: 4px
            .selectedUserTitle
                font-weight: 700
                font-size: 12px
                color: #65656C
    .panelWrapper
        display: flex
        flex-direction: column
        background: white
        .segmentBody
            display: flex
            flex-direction: column
            padding: 16px 24px

    .segmentationError
        font-weight: 400
        font-size: 14px
        color: #EE5253
        margin-top: 24px
    .addNewSegmentationBtn
        display: flex
        justify-content: center
        align-items: center
        flex-direction: row
        background-color: transparent
        padding: 12px 16px
        width: 100%
        height: 48px
        border: 1px solid #FFA351
        border-radius: 8px
        font-weight: 700
        font-size: 18px
        color: #FFA351
        margin-top: 20px
        .addNewSegmentationIcon
            width: 24px
            height: 24px
            margin-right: 4px
    .btnContentWrapper
        display: flex
        flex-direction: row
        .assignPromocodeWrapper
            border: none
            outline: none
            display: flex
            flex: 1
            flex-direction: row
            justify-content: center
            align-items: center
            padding: 16px
            max-height: 56px
            background: #EAE8F3
            width: 100%
            border-right: solid 1px #CBC6E1
            // margin-bottom: 24px
            .percentageIcon
                width: 24px
                height: 24px
                margin-right: 4px
            .assignPromocodeTitle
                font-weight: 700
                font-size: 18px
                color: #503E9D

            &:first-child
                border-end-start-radius: 8px
            &:last-child
                border-end-end-radius: 8px
                border-right: none

    .collapseWrapper, .pureCollapseWrapper
        display: flex
        flex-direction: column
        width: 100%
        border-top: 1px solid #F2F2F7
        border-left: 1px solid #F2F2F7
        border-right: 1px solid #F2F2F7
        overflow: visible
        border-radius: 8px !important
    .headerWrapper
        display: flex
        flex-direction: row
        align-items: center
        margin-bottom: 24px
        // border-radius: 10px
        .headerTitle
            font-weight: 700
            font-size: 24px
            color: #000000
        .headerIconWrapper
            margin-right: 16px
            padding: 12px
            width: 48px
            height: 48px
            background: #F2F2F7
            border-radius: 8px
            display: flex
            justify-content: center
            align-items: center
            .usersIcon
                width: 24px
                height: 24px
    .collapseWrapper:last-of-type
        border-end-end-radius: 8px
        border-end-start-radius: 8px
        border-bottom: 1px solid #F2F2F7
        margin-bottom: 24px
    .pureCollapseWrapper:last-of-type
        border-end-end-radius: 0
        border-end-start-radius: 0
        border-bottom: 0
        margin-bottom: 0
