.wrapper
    display: flex
    flex-direction: row
    display: flex
    flex-direction: row
    align-items: center
    .titleWrapper
        display: flex
        flex-direction: row
        align-items: center
        .editIcon
            width: 16px
            height: 16px
            cursor: pointer
        .title
            font-weight: 700
            font-size: 20px
            color: #000000
            margin-right: 12px
    .pressEnterToSave, .pressEnterToSaveError
        font-weight: 700
        font-size: 12px
        color: #65656C
    .pressEnterToSaveError
        color: #EE5253    
    .segmentTitleInput
        width: 342px
        padding: 8px 12px
        height: 40px
        border: 1px solid #D1D1D6
        border-radius: 8px
        font-weight: 400
        font-size: 16px
        outline: none
        margin-right: 8px
        &:focus
            border: 1px solid #FFA351
        &::placeholder
            color: #65656C
