.mentions--singleLine .mentions__control {
  display: inline-block;
  width: 130px;
}
.mentions--singleLine .mentions__highlighter {
  padding: 1px;
  border: 2px inset transparent;
  background-color: black;
}
.mentions--multiLine .mentions__highlighter textarea {
  border-radius: 8px !important;
}
.mentions--singleLine .mentions__input {
  padding: 1px;
  border: 2px inset;
}

.mentions--multiLine .mentions__control {
  font-family: monospace;
  font-size: 16px;
  font-weight: 600;
  color: #65656c;
}
.mentions--multiLine .mentions__highlighter {
  border: 1px solid transparent;
  padding: 9px;
  height: 96px;
  border-radius: 8px;
}

.mentions__control textarea {
  border-radius: 8px !important;
}
.mentions--multiLine .mentions__input {
  border: 1px solid silver;
  padding: 9px;
  outline: 0;
}

.mentions__suggestions__list {
  background-color: #ffffff;
  box-shadow: 0px 4px 8px rgba(142, 142, 147, 0.12);
  border: 1px solid #e5e5ea;
  border-radius: 8px;
  height: 175px;
  overflow: scroll;
}

.mentions__suggestions__item {
  padding: 8px;
  font-weight: 400;
  font-size: 14px;
  color: #1c1c1c;
}

.mentions__suggestions__item--focused {
  background-color: rgba(255, 163, 81, 0.12);
}

.mentions__mention {
  position: relative;
  z-index: 1;
  color: #1c1c1c;
  font-size: 16px;
  font-weight: 600;
  background-color: #f2f2f7;
  text-shadow: 1px 1px 1px white, 1px -1px 1px white, -1px 1px 1px white,
    -1px -1px 1px white;
  pointer-events: none;
}
