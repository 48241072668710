.wrapper
    width: 250px
    height: 100vh
    overflow: hidden
    display: flex
    flex-direction: column
    align-items: flex-start
    background: #1C1C1E
    .logo
        width: 100%
        height: 72px
        display: flex
        justify-content: center
        align-items: center
        box-shadow: inset 0px -1px 0px rgba(255, 255, 255, 0.12)

        img
            width: 80px
    .link_list
        display: flex
        flex-direction: column
        padding-top: 32px
        padding-right: 16px
        width: 100%
        .link
            display: flex
            justify-content: center
            align-items: center
            height: 56px
            margin-right: 0
            transition: all .3s
            border-radius: 0px 100px 100px 0px
            &:hover
                background: rgba(255, 163, 81, 0.12)
                border-radius: 0px 100px 100px 0px
                .content
                    span
                        color: #FFA351
                    span.image
                        filter: brightness(0) saturate(100%) invert(81%) sepia(28%) saturate(2827%) hue-rotate(322deg) brightness(103%) contrast(101%)
            .content
                width: 200px
                display: flex
                justify-content: flex-start
                span
                    color: #AEAEB2
                    font-weight: 400
                    font-size: 16px
                    margin-left: 10px
                span.image
                    width: 20px
                    height: 20px
                    margin-top: 4px
            &.active
                background: rgba(255, 163, 81, 0.12)
                border-radius: 0px 100px 100px 0px
                .content
                    span
                        color: #FFA351
                    span.image
                        filter: brightness(0) saturate(100%) invert(81%) sepia(28%) saturate(2827%) hue-rotate(322deg) brightness(103%) contrast(101%)
