.addPromo
  margin: 8px 0 0 24px

.wrapper
  margin: 0 0 0 8px

.content
  margin: 16px

.container
  display: flex
  flex-direction: row

.dropdownContainer
  flex-direction: column
  width: 350px
  // justify-content: center
  // align-items: center
  display: flex

.dropdownOptionsContainer
  display: flex
  flex-direction: row
  align-items: center

.flag
  width: 24px
  height: 24px

.dropdownSelectedValue
  margin: 4px 0 0 8px

.dropdownLabel
  color: #65656C
  font-weight: 400
  font-size: 14px

.brandContainer
  margin: 0 0 0 16px

.parentContainer
  margin: 8px
  background: #F5F5F5

.addPromoContainer
  margin: 30px 16px 8px 16px

.formContainer
  background-color: #FFF
  border: 1px solid #F2F2F7
  padding: 16px
  margin: 16px
  border-radius: 8px

.ant-select-open
  color: #fff !important

.promocodeSettingsCotnainer
  display: flex
  justify-content: space-between
  align-items: center
  flex-direction: row

.promocodeDropdownWrapper
  width: 100%

.promocodeTypes
  width: 100%
  margin: 0 16px 0 16px

.divider
  display: flex
  flex-direction: row
  justify-content: space-between
  align-items: flex-start
  height: auto
  *
    width: 100%

.formControl
  display: flex
  flex-direction: column
  justify-content: flex-start
  align-items: flex-start
  paddding: 10px
  height: auto
  margin: 10px 25px 10px
.label
  font-size: 16px
  font-weight: bold
  margin-bottom: 10px

.sectionContainer
  border: 1px solid #E5E5EA
  border-radius: 8px
  background-color: #FFF
  margin: 34px 40px 0 40px
  padding: 24px

.pintMultiplierLabel
  color: #000
  font-weight: 700

.pointMultiplierContainer
  width: 100%
  margin-top: 20px
  margin-left: 14px

.switchToggleContainer, .activeSwitch
  display: flex
  align-items: center
  justify-content: space-between
  background-color: #FFF4EA
  border-radius: 8px
  height: 52px
  margin-top: 16px
  margin-bottom: 20px
  padding: 0 24px 0 24px
  border: 1px solid#E5E5EA

.activeSwitch
  background-color: #F9F9FB

.countryName
  color: #1C1C1C
  font-weight: 500
  font-size: 16px
  margin: 0 16px 0 8px

.addPromoTitleContainer
  margin: 24px 0 0 44px

.addPromoTitle
  color: #1C1C1E
  font-weight: 700
  font-size: 28px
.btn

  height: 60px
  border-radius: 5px
  font-size: 18px
  font-weight: 700
  line-height: 60px
  padding: 0px 25px
  border: solid 1px transparent

  color: white
  &-login
    width: 100%
    &:active &:focus
      outline: none
  &-primary
    width: 100%
    background-color: #FFA351
    &:hover
      background-color: #fff
      color: #FFA351
      cursor: pointer
      border-color: #FFA351

  &-disabled
    width: 100%
    background: #e8f0fe
    color: black
    cursor: not-allowed

  &-transparent
    background: none
    border: none
    color: #FFA351
    cursor: pointer
