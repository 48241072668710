.progress
  background-color: #F2F2F7
  border-radius: 20px
  position: relative
  margin: 15px 0
  height: 12px

.progressDone
  background: #503E9D
  border-radius: 20px
  color: #fff
  display: flex
  align-items: center
  justify-content: center
  height: 100%
  width: 0
  opacity: 0
  transition: 1s ease 0.3s
