.wrapper
    display: flex
    flex-direction: column
    .title
        padding: 8px 24px
        width: 100%
        max-height: 35px
        background: #F2F2F7
        font-weight: 700
        font-size: 14px
        text-transform: uppercase
        color: #65656C
    .filterWrapper
        display: flex
        flex-direction: column
        padding: 24px
        width: 100%
        background: #F9F9FB
        .conditionsWrapper
            padding: 16px 24px
            gap: 16px
            background: #FFFFFF
            border: 1px solid #E5E5EA
            border-radius: 8px
            .conditionWrapper
                display: flex
                flex-direction: row
                justify-content: space-between
                width: 100%
                margin-top: 16px
                align-items: center
                .removeElement
                    cursor: pointer
                .conditionInputs
                    display: flex
                    width: 95%
                    justify-content: space-between
                    & > *
                        width: 33% !important
                    .conditionInputsValuesWrapper
                        display: flex
                        flex-direction: column
                        .conditionValueInputError
                            font-weight: 400
                            font-size: 14px
                            color: #EE5253
                        .conditionValueInput, .conditionValueTextarea
                            padding: 8px 12px
                            // width: 400px
                            height: 38px
                            background: #FFFFFF
                            border: 1px solid #D1D1D6
                            border-radius: 8px

                            font-weight: 400
                            outline: none
                            // margin-left: 8px
                            font-size: 16px
                            line-height: 22px
                            color: #1C1C1C
                        .conditionValueTextarea
                            min-height: 148px
                            max-height: 148px
                            resize: none
                    .fieldWrapper
                        display: flex
                        flex-direction: column
                        margin-right: 8px

            .logical_operator_label
                font-weight: 700
                font-size: 16px
                color: #503E9D
                .logical_operator_dropdown
                    margin-left: 4px
                    path
                        stroke: #503E9D

        .addNewCondition
            outline: none
            border: none
            background-color: transparent
            display: flex
            flex-direction: row
            align-items: center
            font-weight: 700
            font-size: 18px
            color: #FFA351
            margin-top: 24px
            .addNewConditionIcon
                width: 24px
                height: 24px
                margin-right: 4px
.triggrer_styles
    height: 40px
    min-width: 200px
    width: 100%
    border-radius: 8px
    display: flex
    justify-content: space-between
    align-items: center
    border: solid 1px #c7c7c7
    padding: 0px 10px
