.sectionContainer
    display: flex
    flex-direction: column
    padding: 24px
    background: #FFFFFF
    border: 1px solid #E5E5EA
    border-radius: 8px
    margin-bottom: 20px
.divider
    display: flex
    flex-direction: row
    justify-content: space-between
    align-items: flex-start
    height: auto
    flex-wrap: wrap
    & > *
        flex-basis: 45% !important
        padding-right: 20px

.wrapper
    display: flex
    flex-direction: column

    .userTypeError
        font-weight: 400
        font-size: 14px
        color: #EE5253
    .dropdownWrapper
        display: flex
        flex-direction: column
        align-items: flex-start
        .dropdown
            display: flex
            justify-content: space-between
            height: 48px
            border-radius: 8px
            background-color: #fff
            border: 1px solid #D1D1D6
            width: 100%
            padding: 10px
            line-height: 40px
            align-items: center

.label, .sublabel
    font-weight: 400
    font-size: 16px
    color: #1C1C1C
.label
    font-weight: 600
    margin-bottom: 4px

.wrapper
    display: flex
    flex-direction: column
    .title
        padding: 0px 24px
        width: 100%
        height: 40px
        background: #F2F2F7
        font-weight: 700
        font-size: 14px
        text-transform: uppercase
        color: #65656C
        display: flex
        justify-content: space-between
        span
            line-height: 40px
        button
            border: none
            background: none
            font-size: 25px
            line-height: 20px
            font-weight: 700
            &:hover
                cursor: pointer
    .filterWrapper
        display: flex
        flex-direction: column
        padding: 24px
        width: 100%
        background: #F9F9FB

        .addNewCondition
            outline: none
            border: none
            background-color: transparent
            display: flex
            flex-direction: row
            align-items: center
            font-weight: 700
            font-size: 18px
            color: #FFA351
            .addNewConditionIcon
                width: 24px
                height: 24px
                margin-right: 4px

.indicatorSeparator
    display: none
.valueContainer
    display: flex
    font-weight: 700
    font-size: 16px
    line-height: 22px
    color: #503E9D
.indicatorsContainer
    width: 20px
    height: 20px
.menuSelect
    width: 80px !important
.dropdownContainer
    display: flex
    flex-direction: column
    width: 100px
    .dropdownWrapper, .selectedItemWrapper
        background: #FFFFFF
        padding: 8px
        display: flex
        justify-content: center
        width: 100%
        border: 0
        .itemDropdropLabel
            font-weight: 700
            font-size: 16px
            line-height: 22px
            color: #1C1C1C
        &:hover, &:focus
            background: #EAE8F3
            .itemDropdropLabel
                color: #503E9D
    .selectedItemWrapper
        background: #EAE8F3
        .itemDropdropLabel
            color: #503E9D

.dropdownBtn
    width: 80px
    height: 40px
    background: #EAE8F3
    border-radius: 48px
    display: flex
    flex-direction: row
    align-items: center
    justify-content: center
    .dropdownOpened
        transform: rotate(180deg)
    .dropdownLabel
        font-weight: 700
        font-size: 16px
        line-height: 22px
        color: #503E9D
.addNewSegmentationBtn
    display: flex
    justify-content: center
    align-items: center
    flex-direction: row
    background-color: transparent
    padding: 12px 16px
    width: 500px
    height: 48px
    border: 1px solid #FFA351
    border-radius: 8px
    font-weight: 700
    font-size: 18px
    color: #FFA351
    margin-top: 20px
    .addNewSegmentationIcon
        width: 24px
        height: 24px
        margin-right: 4px
.connection_card
    box-sizing: content-box
    padding: 16px 24px
    border: solid 1px #EAE8F3
    border-radius: 8px
    background: #fff
    margin-bottom: 24px
    .card_header
        padding: 8px 0px
        width: 100%
        font-weight: 700
        font-size: 14px
        text-transform: uppercase
        color: #65656C
        display: flex
        justify-content: space-between
        border-bottom: solid 1px #EAE8F3
        align-items: center
        .title
            background: #fff
            line-height: 40px
            display: flex
            flex-direction: row
            justify-content: flex-start
            align-items: center
            padding: 0px
            width: auto
            .content
                font-size: 20px
                font-weight: 700
            .icon
                padding-right: 10px

        button
            border: none
            font-size: 25px
            line-height: 40px
            font-weight: 700
            height: 40px
            width: 40px !important
            border-radius: 20px
            background: #EAE8F3
            &:hover
                cursor: pointer
            &:active
                outline: none
    .communication_body
        margin-top: 24px
        width: 100%
        .content_selection
            margin-bottom: 25px
        .card
            border-radius: 8px
            border: solid 1px #EAE8F3
            position: relative
            padding-top: 20px
            .floating_header
                display: flex
                align-items: center
                justify-content: center
                height: 40px
                line-height: 40px
                position: absolute
                top: -25px
                right: calc( 50% - 56px )
                border-radius: 20px
                width: 112px
                padding: 0px 20px
                border: solid 1px #EAE8F3
                background-color: #fff
                .text
                    font-weight: 700
                    font-size: 14px
                .icon
                    padding-right: 5px
            .content
                padding: 18px
                border-radius: 8px
        .checkbox
            display: flex
            justify-content: flex-start
            align-items: center
            margin-top: 15px
            &:hover
                cursor: pointer
            .label
                padding-left: 5px
                font-weight: 400
                font-size: 14px
            .box
                display: flex
                justify-content: center
                align-items: center
                width: 32px
                height: 32px
                border-radius: 5px
                border: solid 1px #EAE8F3
                background: #F2F2F7
                &.active
                    background: #000
