.wrapper
    display: flex
    height: 72px
    justify-content: space-between
    align-items: center
    background-color: #fff
    padding: 0px 40px
    .pageName
        font-size: 20px
        line-height: 20px
        font-weight: 700
    .user
        display: flex
        width: 64px
        height: 40px
        justify-content: space-between
        align-items: center
        .user_image
            height: 40px
            width: 40px
            border-radius: 20px
            overflow: hidden
            display: flex
            justify-content: center
            align-items: center
            background-color: #F5F5F5
            img
                width: 100%

        .chevron_down
            height: 4px
            width: 8px
