.checkbox
    display: flex
    justify-content: center
    align-items: center
    width: 24px
    height: 24px
    border: solid 1px #E5E5EA
    background: #f2f2f7
    border-radius: 5px
    margin: 0

    img
        width: 12px
        height: 12px
    &_active
        background: #1C1C1E
p.content
    font-size: 14px
    color: #1C1C1C
    padding: 5px 10px
    line-height: 30px
    margin: 0px !important

.container
    background-color: #fff
    display: flex
    flex-direction: column
    width: 100%
    height: 80vh
.header , .filterSection
    display: flex
    justify-content: space-between
    align-items: flex-start
    padding-bottom: 15px
    border-bottom: solid 1px #E5E5EA
.footer
    height: auto
    display: flex
    justify-content: space-between
    align-items: center
    padding-top: 15px
    margin-top: 20px
    border-top: solid 1px #E5E5EA
    .cancel_cta
        padding: 0px
        margin: 0px
        background: #FFA351
        color: #fff
        border: none
        padding: 10px 20px
        border-radius: 8px
        &:focus
            outline: none
        &:disabled
            background: #F2F2F7
            color: #8E8E93

.header
    height: auto
    .headerContent
        display: flex
        p
            height: auto
            margin-bottom: 0px
            line-height: 30px
            padding: 0px 10px
        img
            width: 30px
            color: #1c1c1c
            font-weight: 700
    .close_cta
        padding: 0px
        margin: 0px
        background: transparent
        border: none
        img
            width: 20px
            height: 20px
        &:focus
            outline: none

.filterSection
    height: auto
    padding: 10px 0px
    border-bottom: none

    .select_cta
        flex: 1
        display: flex
        justify-content: center
        align-items: center
        border-radius: 8px
        background: #fff
        border: solid 1px #E5E5EA
        line-height: 42px
        height: 50px
        p
            line-height: 35px
            font-size: 14px
        &:focus
            outline: none

    .search_input
        flex: 6
        padding-right: 10px
        input.input
            padding: 10px
            height: 50px
            background: #fff
            border: solid 1px #E5E5EA
            border-radius: 8px
            width: 100%
            &:focus
                outline: 2px solid #feb114

.invalid
    border: solid 1px #f00
.valid
    border: solid 1px #0f0

.listWrapper
    margin: 0
    padding: 0
    list-style: none
    height: 100vh
    display: grid
    grid-template-rows: repeat(auto-fit, 40px)
    grid-auto-flow: column
    font-size: 50px
    overflow-x: scroll

.loader
    justify-content: center
    align-items: center
    display: flex
    height: 100%
    width: 100%

.round
    width: 30px
    height: 30px
    border-radius: 15px
    background: #f0f0f0
    text-align: center
