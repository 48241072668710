
.sectionContainer
    border: 1px solid #E5E5EA
    border-radius: 8px
    background-color: #FFF
    margin: 34px 40px 0 40px
    padding: 24px

.targeting_tabs_container
    display: flex
    border-radius: 8px
    border: 1px solid #FFA351

.tab_btn
    flex: 1
    color: #FFA351
    background: white
    border: none
    font-size: 16px
    height: 50px
    line-height: 48px
    font-weight: bold
    &:first-child
        border-radius: 8px 0 0 8px
    &:last-child
        border-radius: 0px 8px 8px 0px
    &:nth-child(3n+2)
        border-right: 1px solid #FFA351
        border-left: 1px solid #FFA351
    &.active
        color: white
        background: #FFA351
