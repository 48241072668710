.wrapper
    display: flex
    flex-direction: row
    align-items: center
    min-height: 83px
    border-radius: 8px
    border: 1px solid #D1D1D6
    padding: 16px
    margin-bottom: 50px
    background: #fff
    .image
        width: 64px
    .content
        width: 100%
        .header
            display: flex
            flex-direction: row
            justify-content: space-between
            align-items: center
            .title
                font-weight: bold
                font-size: 18px
                color: #1C1C1C
            .link
                display: flex
                color: #503E9D
                font-size: 14px

        .description
            color: #65656C
            font-size: 14px
