
.indicatorSeparator
    display: none
.valueContainer
    display: flex
    font-weight: 700
    font-size: 16px
    line-height: 22px
    color: #503E9D
.indicatorsContainer
    width: 20px
    height: 20px
.menuSelect
    width: 80px !important
.dropdownContainer
    display: flex
    flex-direction: column
    width: 100%
    .dropdownWrapper, .selectedItemWrapper
        background: #FFFFFF
        padding: 8px
        display: flex
        justify-content: center
        width: 100%
        border: 0
        .itemDropdropLabel
            font-weight: 700
            font-size: 16px
            line-height: 22px
            color: #1C1C1C
        &:hover, &:focus
            background: #EAE8F3
            .itemDropdropLabel
                color: #503E9D
    .selectedItemWrapper
        background: #EAE8F3
        .itemDropdropLabel
            color: #503E9D

.dropdownBtn
    width: 110px
    height: 40px
    background: #EAE8F3
    border-radius: 48px
    display: flex
    flex-direction: row
    align-items: center
    justify-content: center
    .dropdownOpened
        transform: rotate(180deg)
    .dropdownLabel
        font-weight: 700
        font-size: 16px
        line-height: 22px
        color: #503E9D
