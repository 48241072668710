.header
    padding: 8px 0px
    width: 100%
    font-weight: 700
    font-size: 14px
    text-transform: none
    color: #1c1c1e
    display: flex
    justify-content: space-between
    align-items: center
    margin-bottom: 20px
    .title
        background: #fff
        line-height: 40px
        display: flex
        flex-direction: row
        justify-content: flex-start
        align-items: center
        padding: 0px
        width: auto
        .content
            font-size: 20px
            font-weight: 700
            .icon
                padding-right: 10px

    button
        border: none
        font-size: 25px
        line-height: 40px
        // font-weight: 700
        height: 40px
        width: 40px !important
        border-radius: 20px
        background: #F9F9FB
        &:hover
            cursor: pointer
        &:active
            outline: none
.connections_wrapper
    display: flex
    justify-content: center
    align-items: center
    flex-wrap: wrap
    justify-content: space-between

    .connection
        flex-basis: 30%
        border: 1px solid #AEAEB2
        border-radius: 8px
        display: flex
        flex-direction: column
        justify-content: center
        height: 130px
        &:hover
            border: 1px solid #1c1c1c
            cursor: pointer
        *
            width: auto
            margin-bottom: 5px
            text-align: center
            justify-content: center
        .title
            color: #1C1C1C
            font-size: 16px
            font-weight: 700
        .status
            color: #503E9D
            font-size: 12px
            font-weight: 700
            height: 16px
            line-height: 16px

        .image
            width: 30px
            align-self: center
        &.active
            .title  , .image
                opacity: 0.5
