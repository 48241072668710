.dropdownElement
    display: flex
    flex-direction: row
    align-items: center
    padding: 8px
    cursor: pointer
    &:hover
        background-color: #FFF4EA
    .checkbox, .selectedCheckbox
        width: 24px
        height: 24px
        background: #F2F2F7
        border: 1px solid #E5E5EA
        box-shadow: inset 0px 2px 2px -1px rgba(28, 28, 30, 0.1)
        border-radius: 4px
        margin-right: 12px
        display: flex
        justify-content: center
        align-items: center
    .selectedCheckbox
        background-color: #000
.main
    display: flex
    flex-direction: column
    width: 100%
    height: 100%
    .label
        font-weight: 600
        font-size: 16px
        color: #1C1C1C
.allChannelsWrapper
    display: flex
    flex-direction: row
    align-items: center
    box-shadow: inset 0px -1px 0px #F2F2F7
    padding: 8px
    cursor: pointer
    .allChannelsLabel
        font-weight: 400
        font-size: 14px
        color: #000000
    .allChannelsSelectedCheckbox, .allChannelscheckbox
        width: 24px
        height: 24px
        background: #F2F2F7
        border: 1px solid #E5E5EA
        box-shadow: inset 0px 2px 2px -1px rgba(28, 28, 30, 0.1)
        border-radius: 4px
        margin-right: 12px
        display: flex
        justify-content: center
        align-items: center
    .allChannelsSelectedCheckbox
        background-color: #000
