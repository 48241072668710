.wrapper
    display: flex
    flex-direction: column
    padding: 24px
    background: #FFFFFF
    border: 1px solid #E5E5EA
    border-radius: 8px
    .errorMessage
        font-weight: 400
        font-size: 14px
        line-height: 19px
        color: #EE5253
        margin-top: 24px
    .addTimeTriggerBtn
        display: flex
        flex-direction: row
        align-items: center
        justify-content: center
        background-color: transparent
        margin-top: 24px
        font-weight: 700
        font-size: 18px
        line-height: 24px
        color: #FFA351
        width: 198px
        height: 48px
        border: 1px solid #FFA351
        border-radius: 8px
    .header
        display: flex
        flex-direction: row
        align-items: center
        .timeTriggerIconWrapper
            adding: 12px
            width: 48px
            height: 48px
            background: #F2F2F7
            border-radius: 8px
            display: flex
            justify-content: center
            align-items: center
            .timeTriggerIcon
                width: 24px
                height: 24px
        .timeTriggerTitle
            font-weight: 700
            font-size: 24px
            line-height: 33px
            color: #000000
            margin-left: 16px