
.indicatorSeparator
    display: none
.valueContainer
    display: flex
    font-weight: 700
    font-size: 16px
    line-height: 22px
    color: #1C1C1C
.indicatorsContainer
    width: 20px
    height: 20px
.menuSelect
    width: 80px !important
.dropdownContainer
    padding: 10px
    left: 0
    width: 100%
    position: relative
    z-index: 9999999999999999
    .search_container
        background: #FFFFFF
        padding: 8px
        display: flex
        justify-content: flex-start
        width: 100%
        border: 0
        .search
            width: 100%
            height: 50px
            input
                width: 100%
                border: solid 1px #F2F2F7
                height: 45px
                padding: 0px 10px
                line-height: 35px
                border-radius: 8px

                &:focus, &:active
                    outline: solid 2px #F2F2F7
    .dropdownWrapper, .selectedItemWrapper
        background: #FFFFFF
        padding: 8px
        display: flex
        justify-content: flex-start
        width: 100%
        border: 0
        .search
            width: 100%
            height: 40px
            input
                width: 100%
                border: solid 1px #F2F2F7
                height: 35px
                padding: 0px 10px
                line-height: 35px
                border-radius: 8px

                &:focus, &:active
                    outline: solid 2px #F2F2F7

            .searchInputIcon
                margin-right: 1rem
                margin-left: 0.2rem
                margin-bottom: 0.35rem
        .check
            display: flex
            width: 22px
            height: 22px
            border-radius: 3px
            border: solid 1px #1c1c1c
            background: #fff
            justify-content: center
            align-items: center
            line-height: 22px
            &.active
                background: #1c1c1c
                color: white
                font-size: 24px
        .itemDropdropLabel
            font-weight: 500
            font-size: 14px
            line-height: 22px
            color: #1C1C1C
            margin-left: 10px
        &.active
            background: #F2F2F7
        &:hover, &:focus
            background: #F2F2F7
            cursor: pointer
            .itemDropdropLabel
                color: #1C1C1C
    .selectedItemWrapper
        background: #F2F2F7
        .itemDropdropLabel
            color: #1C1C1C

.dropdownBtn
    min-width: 300px
    max-width: 100%
    height: 50px
    background: #F2F2F7
    border-radius: 25px
    display: flex
    flex-direction: row
    align-items: center
    justify-content: space-between
    padding: 0px 10px 0px 15px
    align-self: flex-end
    margin: 0
    .dropdownOpened
        transform: rotate(180deg)
    .dropdownLabel
        font-weight: 400
        font-size: 14px
        line-height: 22px
        color: #1c1c1c
        .title
            font-weight: 700
            font-size: 14px

.disabled
    &:hover
        cursor: not-allowed
