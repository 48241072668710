.main
    width: 100%
    display: flex
    flex-direction: column
    background: #FFFFFF
    border: 1px solid #E5E5EA
    border-radius: 4px
    padding: 24px
    margin-top: 28px
    .note
        ont-weight: 400
        font-size: 18px
        color: #EE5253
        margin-bottom: 24px
    .title
        font-weight: 700
        font-size: 24px
        color: #000000
    .titlesWrapper, .descsWrapper
        display: flex
        flex-direction: row
        width: 100%
        .separator
            margin: 0 12px
        .titleInputWrapper
            display: flex
            flex-direction: column
            width: 100%
            .characterCount
                font-weight: 400
                font-size: 14px
                color: #65656C
            .inputWrapper
                display: flex
                flex-direction: column
                width: 100%
                input
                    height: 48px
    .descsWrapper
        margin-top: 24px                
.titleLabelWrapper
    display: flex
    flex-direction: row
    align-items: center
    justify-content: space-between
    width: 100%      
    .optionalLabel
        font-weight: 400
        font-size: 14px
        color: #65656C
   
    .titleLabel
        font-weight: 600
        font-size: 16px
        color: #1C1C1C
        .arabicLabel
            font-weight: bold
            font-size: 18px
            color: #1C1C1C
            