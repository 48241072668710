.btn
    display: flex
    justify-content: center
    align-items: center
    height: 60px
    border-radius: 5px
    font-size: 18px
    font-weight: 700
    line-height: 60px
    padding: 0px 25px
    border: solid 1px transparent
    display: flex
    justify-content: center
    align-items: center
    color: white
    &-login
        width: 100%
    &:active &:focus
        outline: none
    &-primary
        width: 100%
        background-color: #FFA351
        &:hover
            cursor: pointer

    &-disabled
        background: #e8f0fe
        color: black
        cursor: not-allowed
    &-transparent
        background: none
        border: none
        color: #FFA351
        cursor: pointer
.header
    height: 40px
    width: 100%
    display: flex
    justify-content: center
    aligncitems: center
    background: #EAE8F3
    border-radius: 10px 10px 0px 0px
    p
        font-size: 20px
        font-weight: 700
        color: #503E9D
        line-height: 40px

.container
    min-height: 100vh
    background: #fff
    display: flex
    height: 100vh
    justify-content: center
    align-items: center
    flex-direction: column
.logoWrapper
    display: flex
    justify-content: center
    margin-bottom: 20px
    padding: 20px 10px
    height: auto
    .logo
        width: 150px
        margin: 10px auto

.formWrapper
    height: auto
    border-radius: 10px
    border: solid 1px #eee
    justify-content: center
    align-items: center
    align-content: center
    width: 35vw

.formControl
    display: flex
    flex-direction: column
    justify-content: flex-start
    align-items: flex-start
    paddding: 10px
    height: auto
    margin: 10px 25px 30px

.label
    font-size: 16px
    font-weight: bold
    margin-bottom: 10px
input.input

    padding: 10px
    height: 60px
    border: solid 1px #eee
    border-radius: 5px
    width: 100%
    &:focus
        outline: 1px solid #FFA351

.invalid
    border: solid 1px #f00
.valid
    border: solid 1px #0f0
.center
    align-items: center
