.sectionContainer
    display: flex
    flex-direction: column
    padding: 24px
    background: #FFFFFF
    border: 1px solid #E5E5EA
    border-radius: 8px
    margin-bottom: 20px
.divider
    display: flex
    flex-direction: row
    justify-content: space-between
    align-items: flex-start
    height: auto
    & > *
        width: 50%
        padding-right: 20px
.wrapper
    display: flex
    flex-direction: column

    .userTypeError
        font-weight: 400
        font-size: 14px
        color: #EE5253
    .dropdownWrapper
        display: flex
        flex-direction: column
        align-items: flex-start
        .dropdown
            display: flex
            justify-content: space-between
            height: 48px
            border-radius: 8px
            background-color: #fff
            border: 1px solid #D1D1D6
            width: 100%
            padding: 10px
            line-height: 40px
            align-items: center

.label, .sublabel
    font-weight: 400
    font-size: 16px
    color: #1C1C1C
.label
    font-weight: 600
    margin-bottom: 4px

.wrapper
    display: flex
    flex-direction: column
    .title
        padding: 0px 24px
        width: 100%
        height: 40px
        background: #F2F2F7
        font-weight: 700
        font-size: 14px
        text-transform: uppercase
        color: #65656C
        display: flex
        justify-content: space-between
        span
            line-height: 40px
        button
            border: none
            background: none
            font-size: 25px
            line-height: 20px
            font-weight: 700
            &:hover
                cursor: pointer
    .filterWrapper
        display: flex
        flex-direction: column
        padding: 24px
        width: 100%
        background: #F9F9FB

        .addNewCondition
            outline: none
            border: none
            background-color: transparent
            display: flex
            flex-direction: row
            align-items: center
            font-weight: 700
            font-size: 18px
            color: #FFA351
            .addNewConditionIcon
                width: 24px
                height: 24px
                margin-right: 4px

.indicatorSeparator
    display: none
.valueContainer
    display: flex
    font-weight: 700
    font-size: 16px
    line-height: 22px
    color: #503E9D
.indicatorsContainer
    width: 20px
    height: 20px
.menuSelect
    width: 80px !important
.dropdownContainer
    display: flex
    flex-direction: column
    width: 100px
    .dropdownWrapper, .selectedItemWrapper
        background: #FFFFFF
        padding: 8px
        display: flex
        justify-content: center
        width: 100%
        border: 0
        .itemDropdropLabel
            font-weight: 700
            font-size: 16px
            line-height: 22px
            color: #1C1C1C
        &:hover, &:focus
            background: #EAE8F3
            .itemDropdropLabel
                color: #503E9D
    .selectedItemWrapper
        background: #EAE8F3
        .itemDropdropLabel
            color: #503E9D

.dropdownBtn
    width: 80px
    height: 40px
    background: #EAE8F3
    border-radius: 48px
    display: flex
    flex-direction: row
    align-items: center
    justify-content: center
    .dropdownOpened
        transform: rotate(180deg)
    .dropdownLabel
        font-weight: 700
        font-size: 16px
        line-height: 22px
        color: #503E9D
