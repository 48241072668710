.ant-switch-handle {
  width: 28px;
  height: 28px;
}
.ant-switch-checked .ant-switch-handle {
  left: calc(100% - 25px - 3px);
}
.ant-switch .ant-switch-handle::before {
  background-color: #8e8e93 !important;
}
.ant-switch-checked .ant-switch-handle::before {
  background-color: #fff !important;
}
.ant-switch-handle::before {
  height: 26px;
  width: 26px;
  border-radius: 13px;
}
.ant-switch {
  height: 30px;
  width: 58px !important;
}

@primary-color: #FFA351;@link-color: #FFA351;@success-color: #52c41a;@warning-color: #faad14;@error-color: #f5222d;@font-size-base: 14px;@heading-color: rgba(0, 0, 0, 0.85);@text-color: #1C1C1E;@text-color-secondary: rgba(0, 0, 0, 0.45);@disabled-color: rgba(0, 0, 0, 0.25);@border-radius-base: 4px;@border-color-base: #e5e5ea;