.multi-select-checkbox {
  width: 24px;
  height: 24px;
}

.multi-select-checkbox .ant-checkbox {
  width: 100%;
  height: 100%;
}

.multi-select-checkbox .ant-checkbox .ant-checkbox-inner {
  width: 100%;
  height: 100%;
}

.multiselect-dropdown {
  min-width: 235px !important;
  border: 1px solid #e5e5ea !important;
  box-shadow: 0px 4px 8px rgba(142, 142, 147, 0.12) !important;
  border-radius: 4px !important;
}

@primary-color: #FFA351;@link-color: #FFA351;@success-color: #52c41a;@warning-color: #faad14;@error-color: #f5222d;@font-size-base: 14px;@heading-color: rgba(0, 0, 0, 0.85);@text-color: #1C1C1E;@text-color-secondary: rgba(0, 0, 0, 0.45);@disabled-color: rgba(0, 0, 0, 0.25);@border-radius-base: 4px;@border-color-base: #e5e5ea;