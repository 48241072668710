.wrapper
    display: flex
    flex-direction: row
    align-items: center
    width: 100%
    .label
        font-weight: 600
        font-size: 16px
        color: #1C1C1C
        margin-right: 8px
    .dropdown
        display: flex
        flex-direction: row
        align-items: center
        justify-content: space-between
        padding: 0px
        width: 100%
        height: 40px
        border: 1px solid #D1D1D6
        border-radius: 8px
        padding: 8px 12px
        .dropdownOpening, .dropdownClosed
            width: 24px
            height: 24px
        .dropdownOpening
            transform: rotate(180deg)
        .selectedItem
            font-weight: 400
            font-size: 16px
            color: #1C1C1C

.itemWrapper
    display: flex
    flex-direction: row
    align-items: center
    padding: 8px
    cursor: pointer
    .itemLabel
        display: flex
        font-weight: 400
        font-size: 14px
        color: #000000
        margin-left: 12px
    .checkboxWrapper, .selectedItemWrapper
        display: flex
        justify-content: center
        align-items: center
        width: 24px
        height: 24px
        background: #F2F2F7
        border: 1px solid #E5E5EA
        box-shadow: inset 0px 2px 2px -1px rgba(28, 28, 30, 0.1)
        border-radius: 4px
    .selectedItemWrapper
        background: #1C1C1E
        box-shadow: inset 0px 2px 2px -1px rgba(28, 28, 30, 0.1)
        border-radius: 4px
.layout
    padding: 10px
    left: 0
    width: 100%
    position: relative
    z-index: 9999999999999999
    hr
        border-top: solid 1px #ccc
        margin: 10px 0px
    .search
        position: relative
        border: solid 1px #ccc
        border-radius: 8px
        height: 40px
        .input
            width: 100%
            padding-left: 35px
            border: none
            line-height: 16px
            font-size: 16px
            height: 100%
            border-radius: 8px
            &:focus
                border: none
                outline: none

        .icon
            position: absolute
            top: 7px
            left: 10px
            img
                width: 20px

    .content
        display: flex
        background: #fff
        justify-content: space-between
        align-items: flex-start
        padding: 15px 20px
        margin: 0
        &.active
            background: #ececec
        &:hover
            background: #ececec
        .info
            display: block
            .label
                padding-bottom: 5px
                font-weight: 700
                font-size: 14px
                color: #1c1c1c
            .desccription
                color: #65656C
                font-size: 14px
                front-weight: 400
                padding-bottom: 5px

        .value
            color: #8E8E93
            font-size: 14px
            front-weight: 400
