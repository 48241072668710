.main
    display: flex
    background: #F5F5F5
    width: 100%

    .form
        display: flex
        flex-direction: column
        width: 100%
        .formContent
            display: flex
            flex-direction: column
            padding: 32px
            margin-bottom: 32px
        .submitBtnWrappr
            padding: 8px 32px
            margin-top: 16px
            width: 100%
            position: fixed
            bottom: 0
            background: #FFFFFF
            box-shadow: 0px -4px 8px rgba(142, 142, 147, 0.12)
            width: calc(100% - 250px)
            display: flex
            justify-content: flex-end
            .submitBtn
                display: flex
                justify-content: center
                align-items: center
                padding: 12px 16px
                height: 48px
                background: #FFA351
                border-radius: 8px
                font-weight: 700
                font-size: 16px
                color: #FFFFFF
                border: none
                outline: none