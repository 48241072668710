.container
  display: flex
  flex-wrap: wrap
  flex-direction: row
  border: 1px solid #D1D1D6
  padding: 8px
  border-radius: 8px

.collapsedContainer
  display: flex
  flex-wrap: wrap
  flex-direction: row

.buttonWrapper
  margin: 8px 0 8px 0

.buttonContainer
  display: flex
  flex-direction: row
  justify-content: center
  align-items: center
  height: 48px

.collapsedButton
  flex: 1,
  width: 100%
  background-color: transparent
  border: 1px solid #FFA351
  height: 40px
  border-radius: 8px

.buttonTitleContainer
  margin-right: 16px

.buttonTitle
  font-weight: 600
  color: #FFA351

.collapesdIcon
  height: 32px

.targetBrandContainer
  margin-top: 8px