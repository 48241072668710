.main
    display: flex
    flex-direction: column
    padding: 41px 32px
    .headerWrapper
        display: flex
        flex-direction: row
        align-items: center
        justify-content: space-between
        .createTemplateWrapper
            outline: none
            border: none
            display: flex
            justify-content: space-between
            align-items: center
            height: 56px
            background: #FFA351
            border-radius: 8px
            padding: 16px
            .createTemplateTitle
                font-weight: 700
                font-size: 16px
                line-height: 22px
                color: #FFFFFF
        .headerTitle
            font-weight: 700
            font-size: 28px
            line-height: 38px
            color: #1C1C1E
    // new table styles  
    .tableWrapper
        width: 100%
        display: flex
        flex-direction: column
        justify-content: center
        background: #FFFFFF
        border: 1px solid #E5E5EA
        box-sizing: border-box
        border-radius: 4px
        margin-top: 3rem
        .tableLoader
            display: flex
            justify-content: center
            padding: 2rem
        .tableHead
            .tableHeadRowTwo
                padding: 2rem
                width: 100%
                margin-top: 2rem
                display: flex
                justify-content: flex-end
                align-items: center
            .tableHeadRowOne
                padding: 2rem 0.5rem 1.5rem
                margin-bottom: 0rem
                width: 100%
                display: flex
                justify-content: space-between
                align-items: flex-start
                .title
                    padding-left: 10px
                    font-size: 20px
                    display: flex
                    align-items: center
                    height: 100%
                    line-height: 40px
                    width: auto !important
                .searchAndActions
                    display: flex
                    justify-content: flex-end
                    align-items: flex-start

                    .search
                        margin-right: 1rem
                        margin-left: 1rem
                        height: 40px
                        .searchInputIcon
                            margin-right: 1rem
                            margin-left: 0.2rem
                            margin-bottom: 0.35rem
                    .export
                        margin-right: 1rem
                        margin-left: 1rem
                        height: 55px
                        .exportButtonIcon
                            margin-right: 0.5rem
                            margin-left: 0.2rem
                            margin-bottom: 0.35rem

        .selectedTab
            background: rgba(255, 163, 81, 0.04)
            box-shadow: inset 0px -1px 0px #FFA351
.download_file_link
    text-decoration: none
    &:active , &:visited, &:hover
        color: #fff
        text-decoration: none

// actions container styles
.actions_wrapper
    display: flex
    justify-content: flex-end
    width: 100%
    overflow: hidden
    .image_wrapper
        width: 30px
        img
            width: 100%
    .actions
        display: none
        position: absolute
        &.open
            right: 20px
            top: 60px
            width: 155px
            border-radius: 5px
            flex-direction: column
            display: flex
            border: solid 1px #f2f2f7
            z-index: 9999999

        button.action_cta
            width: 100%
            height: 35px
            font-weight: 500
            font-size: 14px
            border: none
            outline: none
            background: #fff
            text-align: left
            padding: 0px 15px
            transition: .2s ease-in-out
            &:first-child
                border-radius: 5px 5px 0 0
            &:last-child
                border-radius: 0 0 5px 5px
            &:focus
                outline: none
            &.danger
                color: #EE5253
            &:hover
                background: #f2f2f7
.filtersWrapper
    display: flex
    justify-content: flex-end
    padding: 0px 20px 1.5rem

.action
    border-radius: 5px
    font-size: 14px
    line-height: 30px
    height: 30px
    display: inline-block
    margin-right: 5px
    padding: 0px 5px
    font-weight: 500
    background: #E5E5EA
    text-align: center
.status
    border-radius: 5px
    font-size: 14px
    line-height: 30px
    height: 30px
    display: inline-block
    margin-right: 5px
    padding: 0px 5px
    font-weight: 500
    text-align: center
    width: 60%
    color: #fff
    justify-content: center
.campaign_cell
    display: flex
    flex-direction: column
    .title
        font-weight: 400
        color: #1c1c1e
        padding-bottom: 10px
        font-size: 14px
.endMessageWrapper
    display: flex
    justify-content: center
    align-items: center
    padding: 15px 10px
    color: #f09440
    font-size: 16px
