.brandItemContainer
  background-color: #EEE
  border: 1px solid #E5E5EA
  align-items: center
  justify-content: center
  display: flex
  border-radius: 8px
  margin: 8px
  height: 28px
  padding-top: 5px
  padding-left: 16px
  padding-right: 16px

.clonseIconContainer
  margin-left: 8px
  height: 30px
  cursor: pointer

.closeIcon
  width: 16px
  height: 16px

.brandName
  width: 100px
  overflow: hidden
  text-overflow: ellipsis
  white-space: nowrap
