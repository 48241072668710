.time_triggers_repeat_start {
    direction: rtl;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 48px;
    border: 1px solid #d1d1d6;
    border-radius: 8px;
    border-color: #d1d1d6;
    margin-right: 16px;
    width: 138px;
}

.time_triggers_repeat_start_date{
    direction: rtl;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 48px;
    border: 1px solid #d1d1d6;
    border-radius: 8px;
    border-color: #d1d1d6;
    margin-right: 16px;
    width: 256px;

}

.time_triggers_repeat_start > .ant-picker:hover, .ant-picker-focused {
    border-color: #d1d1d6;

}
.time_triggers_repeat_start .ant-picker-input .ant-picker-suffix {
  margin-left: 0;
  color: #8e8e93;
  font-size: 18px;
}

.time_triggers_repeat_start .ant-picker-input > input {
  direction: ltr;
  text-align: left;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  display: flex;
  align-items: center;
  color: #1c1c1c;
  text-transform: uppercase;
  margin-left: 15px;
}

.time_triggers_repeat_start .ant-picker-input > input:placeholder-shown {
    text-transform: none;
}


.time_triggers_repeat_start_date .ant-picker-input > input {
    text-align: left;
    margin-left: 15px;
    direction: ltr;
}

.time_triggers_repeat_start_date .ant-picker-input > input:placeholder-shown {
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #1C1C1C;
}

.time_triggers_repeat_start_date .ant-picker-input .ant-picker-suffix {
    margin-left: 0;
    color: #8e8e93;
    font-size: 18px;
}

.time_triggers_repeat_start_error {
    direction: rtl;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 48px;
    border: 1px solid #EE5253;
    border-radius: 8px;
    border-color: #EE5253;
    margin-right: 16px;
    width: 138px;
}

.time_triggers_repeat_start_error  > .ant-picker:hover, .ant-picker-focused {
    border-color: #d1d1d6;

}
.time_triggers_repeat_start_error  .ant-picker-input .ant-picker-suffix {
  margin-left: 0;
  color: #8e8e93;
  font-size: 18px;
}

.time_triggers_repeat_start_error  .ant-picker-input > input {
  direction: ltr;
  text-align: left;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  display: flex;
  align-items: center;
  color: #1c1c1c;
  text-transform: uppercase;
  margin-left: 15px;
}

.time_triggers_repeat_start_error  .ant-picker-input > input:placeholder-shown {
    text-transform: none;
}
@primary-color: #FFA351;@link-color: #FFA351;@success-color: #52c41a;@warning-color: #faad14;@error-color: #f5222d;@font-size-base: 14px;@heading-color: rgba(0, 0, 0, 0.85);@text-color: #1C1C1E;@text-color-secondary: rgba(0, 0, 0, 0.45);@disabled-color: rgba(0, 0, 0, 0.25);@border-radius-base: 4px;@border-color-base: #e5e5ea;