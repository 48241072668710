
.formWrapper
    height: auto
    border-radius: 10px
    border: solid 1px #eee
    justify-content: center
    align-items: center
    align-content: center
    width: 35vw
.formContainer
    background-color: #FFF
    border: 1px solid #F2F2F7
    padding: 16px
    margin: 16px
    border-radius: 8px
    background: #F9F9FB

.divider
    display: flex
    flex-direction: row
    justify-content: space-between
    align-items: flex-start
    min-height: 50px
    *
        width: 100%
.split_wrapper
    display: flex
    justify-content: space-between
    margin: 10px 25px

.toggle_container
    display: flex
    justify-content: space-between
    align-items: center
    width: 100%

.split_division
    display: flex
    justify-content: flex-start
    flex-direction: column
    flex-wrap: wrap
    width: 33%
    margin: 0
    border: solid 1px #e5e5ea
    border-radius: 10px
    align-self: flex-start
    background: #F9F9FB
    margin-bottom: 10px
    &.active
        background: #fff4ea
.split_division:nth-child(3n + 2)
    margin: 0px 8px
.formControl
    display: flex
    flex-direction: column
    justify-content: flex-start
    align-items: flex-start
    height: auto
    margin: 10px 25px 10px
.toggle_header
    font-weight: 700
    margin: 0
    font-size: 20px
    line-height: 30px
