.wrapper
    display: flex
    flex-direction: row
    align-items: center
    width: 100%
    .label
        font-weight: 600
        font-size: 16px
        color: #1C1C1C
        margin-right: 8px
    .dropdownWrapper, .errorDropdownWrapper
        display: flex
        flex-direction: column
        align-items: center
        justify-content: space-between
        padding: 0px
        width: 100%
        height: 40px
        border: 1px solid #D1D1D6
        border-radius: 8px
        padding: 8px 0
        .error
            margin: 4px 0 
            width: 100%
            font-weight: 400
            font-size: 14px
            color: #EE5253
            display: flex
            justify-content: flex-start
        .dropdown
            width: 100%
            display: flex
            flex-direction: row
            align-items: center
            justify-content: space-between
            padding: 0 12px
            .dropdownOpening, .dropdownClosed
                width: 24px
                height: 24px
            .dropdownOpening
                transform: rotate(180deg)    
            .selectedItem
                font-weight: 400
                font-size: 16px
                color: #1C1C1C        
    .errorDropdownWrapper
        border:  1px solid #EE5253
.itemWrapper
    display: flex
    flex-direction: row
    align-items: center
    padding: 8px        
    cursor: pointer
    .itemLabel
        display: flex
        font-weight: 400
        font-size: 14px
        color: #000000
        margin-left: 12px
    .checkboxWrapper, .selectedItemWrapper
        display: flex
        justify-content: center
        align-items: center
        width: 24px
        height: 24px       
        background: #F2F2F7
        border: 1px solid #E5E5EA
        box-shadow: inset 0px 2px 2px -1px rgba(28, 28, 30, 0.1)
        border-radius: 4px
    .selectedItemWrapper
        background: #1C1C1E
        box-shadow: inset 0px 2px 2px -1px rgba(28, 28, 30, 0.1)
        border-radius: 4px    