.side-bar-collapse {
  padding-right: 16px !important;
  width: 100% !important;
  background-color: #1c1c1e !important;
}
.side-bar-collapse .ant-collapse-header {
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  background-color: #1c1c1e !important;
}

.side-bar-collapse .ant-collapse-header .ant-collapse-expand-icon {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.active-ant-collapse-header > .ant-collapse-header {
  padding: 0 !important;
  background: rgba(255, 163, 81, 0.12) !important;
  border-radius: 0px 100px 100px 0px !important;
}

.sidebar-ant-collapse-header .ant-collapse-header {
  padding: 0 !important;
}

.sidebar-ant-collapse-header .ant-collapse-header .ant-collapse-expand-icon {
  padding-left: 8px;
}
.active-ant-collapse-header .ant-collapse-header .ant-collapse-expand-icon {
  padding-left: 8px;
}
.active-ant-collapse-header .ant-collapse-content .ant-collapse-content-box {
  display: flex !important;
  flex-direction: column !important;
  padding-left: 58px;
  padding-right: 0;
}
.active-ant-collapse-header > .ant-collapse-header .ant-collapse-arrow svg {
  color: #ffa351 !important;
  width: 16px !important;
  height: 16px !important;
}
.active-ant-collapse-header > .ant-collapse-header .ant-collapse-arrow {
  margin-right: 0 !important;
}
.sidebar-ant-collapse-header .ant-collapse-content .ant-collapse-content-box {
  display: flex !important;
  flex-direction: column !important;
  padding-left: 58px;
  padding-right: 0;

}

.sidebar-ant-collapse-header > .ant-collapse-header .ant-collapse-arrow svg {
  color: #ffa351 !important;
  width: 16px !important;
  height: 16px !important;
}

@primary-color: #FFA351;@link-color: #FFA351;@success-color: #52c41a;@warning-color: #faad14;@error-color: #f5222d;@font-size-base: 14px;@heading-color: rgba(0, 0, 0, 0.85);@text-color: #1C1C1E;@text-color-secondary: rgba(0, 0, 0, 0.45);@disabled-color: rgba(0, 0, 0, 0.25);@border-radius-base: 4px;@border-color-base: #e5e5ea;