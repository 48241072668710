.main
    display: flex
    flex-direction: column
    width: 100%
    padding: 24px
    background: #FFFFFF
    border: 1px solid #E5E5EA
    border-radius: 8px
    .title
        font-weight: 700
        font-size: 24px
        color: #000000
        margin-bottom: 24px
    .fieldsWrapper
        width: 100%
        display: flex
        flex-direction: row
        .separator
            margin: 0 12px
        .templateNameInput
            width: 100%
            input
              height: 48px
              background: #FFFFFF
              width: 100%

