.wrapper
    display: flex
    justify-content: flex-start
    align-items: flex-start
    .content
        width: 100%
        height: 100vh
        overflow-y: scroll
    .content::-webkit-scrollbar
        display: none
