.wrapper
    display: flex
    flex-direction: column
    width: 100%
    border-top: 1px solid #F2F2F7
    border-left: 1px solid #F2F2F7
    border-right: 1px solid #F2F2F7
    overflow: hidden
    &:first-of-type
    .headerWrapper
        display: flex
        flex-direction: row
        align-items: center
        padding: 16px 24px
        width: 100%
        height: 72px
        background: #FFFFFF
        box-shadow: 0px 4px 8px rgba(142, 142, 147, 0.12)
        cursor: pointer
        border-radius: 10px
        .iconWrapper
            cursor: pointer
            display: flex
            justify-content: center
            align-items: center
            padding: 8px
            width: 40px
            height: 40px
            background: #F9F9FB
            border: 1px solid #E5E5EA
            border-radius: 104px
            margin-right: 12px
            .activeIcon, .inactiveIcon
                width: 24px
                height: 24px
            .inactiveIcon
                transform: rotate(-90deg)
    .title
        font-weight: 700
        font-size: 20px
        color: #000000
    .activeCard
        adding: 16px 24px
        height: auto
        width: 100%
        background: #F9F9FB
    .inactiveCard
        max-height: 0

.wrapper:last-of-type
    border-end-end-radius: 8px
    border-end-start-radius: 8px
    margin-bottom: 24px
    border-bottom: 1px solid #F2F2F7
