.listItem
    display: flex
    width: 200px
    align-items: center
    cursor: pointer

.labelDivision
    font-size: 24px
    color: #1C1C1C

.checkbox
    display: flex
    justify-content: center
    align-items: center
    width: 24px
    height: 24px
    border: solid 1px #E5E5EA
    background: #f2f2f7
    border-radius: 5px
    margin: 0
    &_active
        background: #1C1C1E
p.content
    font-size: 14px
    color: #1C1C1C
    padding: 5px 10px
    line-height: 30px
    margin: 0px !important
