.notFoundContainer
    display: flex
    justify-content: center,
    align-items: center,
    width: 100%,
    height: 100%,
    flex-direction: column,
.imageContainer
    height: auto
    margin-bottom: 10px
    img
        width: 70px
        height: 70px
.textContainer
    height: auto
    display: flex
    justify-content: center
    align-items: center
    flex-direction: column
    p
        font-size: 16px
        margin-bottom: 10px
