.filed .ant-select:not(.ant-select-customize-input) .ant-select-selector {
  height: 48px;
  display: flex;
  align-items: center;
  padding: 13px 16px;
}

.overlayWrapper{ 
  background-color: #fff !important;
  left: 333px !important;
}

.menu-items {
  display: flex;
  flex-direction: column;
}

.menu-items .ant-dropdown-menu-item {
  display: flex;
  direction: rtl;
}
@primary-color: #FFA351;@link-color: #FFA351;@success-color: #52c41a;@warning-color: #faad14;@error-color: #f5222d;@font-size-base: 14px;@heading-color: rgba(0, 0, 0, 0.85);@text-color: #1C1C1E;@text-color-secondary: rgba(0, 0, 0, 0.45);@disabled-color: rgba(0, 0, 0, 0.25);@border-radius-base: 4px;@border-color-base: #e5e5ea;