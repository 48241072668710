.collapseItem,
.activeCollapseItem
    font-size: 16px
    color: #aeaeb2
    text-decoration: none !important
    padding-top: 10px
    padding-bottom: 11px
    &:hover
        font-weight: bold
        color: #ffa351
.activeCollapseItem
    font-weight: bold
    color: #ffa351