.container
  background-color: #FDEAEA
  height: 44px
  display: flex
  align-items: center
  flex-direction: row
  border-radius: 8px
  margin: 8px 0 8px 0

.warningImage
  margin: 0 16px 0 16px

.warningTextContainer
  display: flex
  align-items: flex-end
  flex: 1
  height: 44px

.warningText
  color: #EE5253
  font-weight: 500
