.checkbox
    display: flex
    justify-content: center
    align-items: center
    width: 24px
    height: 24px
    border: solid 1px #E5E5EA
    background: #f2f2f7
    border-radius: 5px
    margin: 0
    &_active
        background: #1C1C1E
p.content
    font-size: 14px
    color: #1C1C1C
    padding: 5px 10px
    line-height: 30px
    margin: 0px !important

.content
    padding: 30px 0px 10px
    display: flex
    justify-content: center
    align-items: center
    flex-direction: column
    .imageWrapper
        width: 100px
        height: 100px
        margin-bottom: 20px
    .message
        display: flex
        justify-content: center
        align-items: center
        flex-direction: column

        .contentHeader
            height: auto
            text-align: center
            font-size: 20px
            font-weight: 700

        .contentMessage
            font-size: 16px
            text-align: center

.container
    background-color: #fff
    display: flex
    flex-direction: column
    width: 100%
.header , .filterSection
    display: flex
    justify-content: flex-end
    align-items: flex-start
    padding-bottom: 15px
    border-bottom: solid 1px #E5E5EA
.footer
    height: auto
    display: flex
    justify-content: flex-end
    align-items: center
    padding-top: 15px
    margin-top: 20px
    border-top: solid 1px #E5E5EA
    .cancel_cta
        padding: 0px
        margin: 0px
        background: #EE5253
        color: #fff
        border: none
        padding: 10px 20px
        border-radius: 8px
        &:focus
            outline: none
        &:disabled
            background: #F2F2F7
            color: #8E8E93

.header
    height: auto
    justify-content: flex-end
    .headerContent
        color: #1c1c1c
        font-weight: 700
    .close_cta
        padding: 0px
        margin: 0px
        background: #F2F2F7
        border: none
        img
            width: 20px
            height: 20px
        &:focus
            outline: none

.filterSection
    height: auto
    padding: 10px 0px
    border-bottom: none

    .select_cta
        flex: 1
        display: flex
        justify-content: center
        align-items: center
        border-radius: 8px
        background: #fff
        border: solid 1px #E5E5EA
        line-height: 42px
        height: 50px
        &:focus
            outline: none

    .search_input
        flex: 6
        padding-right: 10px
        input.input
            padding: 10px
            height: 50px
            background: #fff
            border: solid 1px #E5E5EA
            border-radius: 8px
            width: 100%
            &:focus
                outline: 2px solid #feb114

.invalid
    border: solid 1px #f00
.valid
    border: solid 1px #0f0

.listWrapper
    margin: 0
    padding: 0
    list-style: none
    height: 100vh
    display: grid
    grid-template-rows: repeat(auto-fit, 40px)
    grid-auto-flow: column
    font-size: 50px
    overflow-x: scroll

.listItem
    display: flex
    width: 200px
    align-items: center

.labelDivision
    font-size: 24px
    color: #1C1C1C

.notFoundContainer
    display: flex
    justify-content: center,
    align-items: center,
    width: 100%,
    height: 100%,
    flex-direction: column,
.imageContainer
    height: auto
    margin-bottom: 10px
    img
        width: 70px
        height: 70px
.textContainer
    height: auto
    display: flex
    justify-content: center
    align-items: center
    flex-direction: column
    p
        font-size: 16px
        margin-bottom: 10px
.center
    width: 30px
    height: 30px
    border-radius: 15px
    background: #f0f0f0
    text-align: center
