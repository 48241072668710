.formControl
    display: flex
    flex-direction: column
    justify-content: flex-start
    align-items: flex-start
    paddding: 10px
    height: auto
    margin: 10px 25px 20px

.label
    font-size: 16px
    font-weight: 600
    margin-bottom: 10px
    width: 100% !important
input.input
    padding: 5px 10px
    height: 60px
    border: 1px solid rgb(229, 229, 234)
    border-radius: 10px
    width: 100%
    &:focus
        outline: 1px solid #FFA351

.invalid
    border: solid 1px #f00 !important
.valid
    border: solid 1px #0f0
.withBadge
    position: relative
    width: auto
    border-radius: 10px
    width: 100% !important
    .badge
        position: absolute
        display: flex
        justify-content: center
        align-items: center
        top: 0
        right: 0
        bottom: 0
        width: 60px
        border-radius: 0 10px 10px 0
        background: #E5E5EA
        color: #1C1C1C
        &.invalid
            border-top: solid 1px #f00 !important
            border-bottom: solid 1px #f00 !important
            border-right: solid 1px #f00 !important
            border-left: solid 1px transparent !important
