.wrapper
    display: flex
    flex-direction: column
    width: 100%
    .userTypeError
        font-weight: 400
        font-size: 14px
        color: #EE5253
    .dropdownWrapper
        display: flex
        flex-direction: column
        align-items: flex-start
        div
            height: 48px
            border-radius: 8px
            background-color: #fff

    .field

.label, .sublabel
    font-weight: 400
    font-size: 16px
    color: #1C1C1C
.label
    font-weight: 600
    margin-bottom: 4px

.triggrer_styles
    height: 40px
    min-width: 200px
    width: 100%
    border-radius: 8px
    display: flex
    justify-content: space-between
    align-items: center
    border: solid 1px #c7c7c7
    padding: 0px 10px
