.wrapper
    display: flex
    flex-direction: column
    .label
        font-weight: 600
        font-size: 16px
        color: #1C1C1C
        margin-bottom: 8px
    .targeting_tabs_container
        display: flex
        .tab_btn
            flex: 1
            color: #FFA351
            background: white
            border: none
            font-size: 16px
            height: 50px
            line-height: 48px
            font-weight: bold
            border: 1px solid #FFA351
            &:first-child
                border-radius: 10px 0px 0px 10px
                border-right: none
            &:last-child
                border-radius: 0px 10px 10px 0px
                border-left: none
            &:nth-child(3n+2)
                border-right: 1px solid #FFA351
                border-left: 1px solid #FFA351
            &.active
                color: white
                background: #FFA351    