
.sectionContainer
    display: flex
    flex-direction: column
    padding: 24px
    background: #FFFFFF
    border: 1px solid #E5E5EA
    border-radius: 8px
    margin-bottom: 20px
.divider
    display: flex
    flex-direction: row
    justify-content: space-between
    align-items: flex-start
    height: auto
    *
        width: 50%
.wrapper
    display: flex
    width: 100%
    align-items: flex-start
    .campaign_title
        flex: 6
    .campaign_date
        flex: 4
