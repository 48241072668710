.logical_operator {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px 12px;
  width: 80px;
  height: 40px;
  background: #eae8f3;
  border-radius: 48px;
}

.logical_operator_select {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px 12px;
  width: 80px;
  height: 40px;
  background: #eae8f3;
  border-radius: 48px;
}
.logical_operator_select .ant-select-selection-item {
    background-color: transparent;
    padding: 0 !important;

}
.logical_operator_select .ant-select-selector{
    padding:  0 !important;
    background-color: transparent !important;
}
.logical_operator_select .ant-select-selector::after {
    background-color: transparent !important;
    line-height: normal !important;
}
.logical_operator_select .ant-select-selector .ant-select-selection-item {
  font-weight: 700 !important;
  font-size: 16px !important;
  color: #503e9d !important;

}
.error-field-dropdown{
  height: 40px !important;
  min-width: 400px !important;
  width: 100%;
}

.error-field-dropdown  .ant-select-selector .ant-select-selection-placeholder {
  color: #1c1c1c !important;
}
.error-field-dropdown  .ant-select-selector{
  height: 40px !important;
  min-width: 400px !important;
  border: 1px solid #EE5253 !important;
  border-radius: 8px !important;
}
.field-dropdown {
  height: 40px !important;
  min-width: 400px !important;
  width: 100%;
}
.field-dropdown .ant-select-selector {
  height: 40px !important;
  min-width: 400px !important;
  border: 1px solid #d1d1d6 !important;
  border-radius: 8px !important;
}
.field-dropdown .ant-select-selector .ant-select-selection-placeholder {
  color: #1c1c1c !important;
}
.operator-dropdown {
  height: 40px !important;
  width: 100%;
  min-width: 128px !important;
}
.operator-dropdown .ant-select-selector .ant-select-selection-placeholder {
  color: #1c1c1c !important;
}

.operator-dropdown .ant-select-selector {
  height: 40px !important;
  min-width: 128px !important;
  border: 1px solid #d1d1d6 !important;
  border-radius: 8px !important;
}

.value-dropdown {
  height: 40px !important;
  width: 100%;
  min-width: 400px !important;
}

.value-dropdown .ant-select-selector {
  height: 40px !important;
  min-width: 400px !important;
  border: 1px solid #d1d1d6 !important;
  border-radius: 8px !important;
}

@primary-color: #FFA351;@link-color: #FFA351;@success-color: #52c41a;@warning-color: #faad14;@error-color: #f5222d;@font-size-base: 14px;@heading-color: rgba(0, 0, 0, 0.85);@text-color: #1C1C1E;@text-color-secondary: rgba(0, 0, 0, 0.45);@disabled-color: rgba(0, 0, 0, 0.25);@border-radius-base: 4px;@border-color-base: #e5e5ea;