.message-on-time-picker {
  min-width: 138px;
  height: 40px;
  border: 1px solid #d1d1d6;
  border-radius: 8px;
  direction: rtl;
}
.message-on-time-picker .ant-picker-input .ant-picker-suffix {
  margin-left: 0;
  color: #8e8e93;
  font-size: 18px;
}

.message-on-time-picker .ant-picker-input > input:placeholder-shown {
  text-align: left;
  margin-left: 14px;
}

.message-on-time-picker .ant-picker-input > input {
  text-align: left;
  margin-left: 14px;
  direction: ltr;
  text-transform: uppercase;
}
.dropdown-for-select-day {
  left: 990px !important;
}

@primary-color: #FFA351;@link-color: #FFA351;@success-color: #52c41a;@warning-color: #faad14;@error-color: #f5222d;@font-size-base: 14px;@heading-color: rgba(0, 0, 0, 0.85);@text-color: #1C1C1E;@text-color-secondary: rgba(0, 0, 0, 0.45);@disabled-color: rgba(0, 0, 0, 0.25);@border-radius-base: 4px;@border-color-base: #e5e5ea;